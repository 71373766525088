import React, { useState, useEffect } from "react"
import Helmet from 'react-helmet'
import Fight from '../components/Fight'
import { Loading } from '@geist-ui/react'
import queryString from 'query-string'


export default function FightPage (props) {

	const [loading, setLoading] = useState()
	const [fight, setFight] = useState({})

	const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

	useEffect(() => {
	  if(parsed.fight) {
		  let fightread = "fights" + "-" + parsed.fight;
	      fetch(`/.netlify/functions/fauna-read/${fightread}`).then(response => {
	          return response.json().then(response => {
	          	if(response.data.title) {
		          	setFight({'id': response.ref['@ref'].id, 'title': response.data.title,
		          	'fighterone': response.data.fighterone, 'fightertwo': response.data.fightertwo,
		          	'sport': response.data.sport});
		          	setLoading(false);
	          	}
	          	else {
	          		setLoading(false);
	          	}
	          })
	          .catch(error => setLoading(false))
	      });
	  }

	}, [parsed.fight])

  return (
    <>
      <Helmet>
      <title>Vote for the Fights You Want to See.</title>
      <meta name="description" content={"We're on a mission to put the power back in the hands of the fans."} />
      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
      {loading && <Loading spaceRatio={2.5} />}
      {!loading && fight.title && 
      	<Fight fight={fight} share="true" parsed={parsed.fight} login={parsed.login} />}
    </>
  )
}